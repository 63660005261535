.ember-light-table-wrapper {
  width: 100%;
  overflow-x: scroll;
}

.ember-light-table {
  .lt-head table {
    margin-bottom: 0;
  }

  .lt-body-wrap {
    overflow-y: auto;
  }

  .lt-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .total-cell {
    text-align: right;
  }
}

.ace-datetimepicker {
  display: block;
}

.tags-field input {
  padding-left: 10px !important
}

.ember-power-select-multiple-trigger {
  height: auto !important;
  min-height: 30px;
  max-height: 100px;
}

// Took from Bootstrap CSS 4, as fallback if app is using Bootstrap CSS 3
.input-group {
  display: flex;

  .form-control {
    flex: 1 1 auto;
    width: 1%;
  }

  .input-group-btn {
    padding: 0;
    border: unset;
    width: auto;
  }

  .input-group-text {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1.25rem;
    border: 1px solid #ced4da;
  }
}
